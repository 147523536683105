.nav-item {
  flex: 0 0 auto;
  width: auto;
}

.modal {
  &-close {
    position: absolute;
    right: 2.5rem;
  }
}
